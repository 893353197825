// HEADING

@import "core";

@include respond-to(base-inline) {
  :where(.tc_heading){font-weight:var(--font-medium);line-height:var(--line-110);overflow-wrap:break-word;
    svg{display:inline-block;width:1.6ex;height:1.6ex;}
    &.tc_heading--weight-300{font-weight:var(--font-light);}
    &.tc_heading--weight-400{font-weight:var(--font-regular);}
    &.tc_heading--weight-500{font-weight:var(--font-medium);}
    &.tc_heading--weight-700{font-weight:var(--font-bold);}
    &.tc_heading--slant{font-style:italic;font-variation-settings:'slnt' 10, 'ital' 1;}
  }
  :where(.tc_heading--uppercase){text-transform:uppercase;}
  :where(.tc_heading--align-center){text-align:center;}
  :where(.tc_heading__link){color:inherit;text-decoration:none;}
  :where(.tc_heading__prefix){
    img{display:inline-block;height:1.4ex;margin-right:var(--spacing-4);}
  }

  :where([class*="tc_heading--color"]){color:var(--color-primary-tv2red);}
  :where(.tc_heading--color-sport){color:var(--color-primary-blue);}
  :where(.tc_heading--color-vejr){color:var(--color-primary-green);}
  :where(.tc_heading--color-echo){color:var(--color-primary-violet);}
  :where(.tc_heading--color-breaking){color:var(--color-breaking);}
  :where(.tc_heading--color-light){color:var(--color-light);}

  :where(.tc_heading--1){font-size:var(--font-32-36-48);font-weight:var(--font-bold);}
  :where(.tc_heading--2){font-size:var(--font-28-32-36);font-weight:var(--font-bold);}
  :where(.tc_heading--3){font-size:var(--font-24-28);}
  :where(.tc_heading--4){font-size:var(--font-20);}
  :where(.tc_heading--5){font-size:var(--font-18);}
  :where(.tc_heading--6){font-size:var(--font-16);}
}

@include respond-to(base) {
  :where(.tc_heading--arrow .tc_heading__link){position:relative;padding-right:1.5ex;
    &::after{content:"";position:absolute;top:0;bottom:0;right:0;width:1ex;mask:url("inline!../../../../icons/chevron-right.svg") no-repeat 100% 60%;mask-size:auto 1.3ex;background-color:var(--color-link);}
  }
}

@include respond-to(print) {
  .tc_heading{font-weight:var(--font-medium);line-height:var(--line-110);margin-bottom:var(--spacing-8);}
  .tc_heading--weight-400{font-weight:var(--font-regular);}
  .tc_heading--weight-700{font-weight:var(--font-bold);}
  .tc_heading--slant{font-style:italic;font-variation-settings:'slnt' 10, 'ital' 1;}
  .tc_heading--uppercase{text-transform:uppercase;}

  .tc_heading--1{font-size:var(--font-48);font-weight:var(--font-bold);}
  .tc_heading--2{font-size:var(--font-36);font-weight:var(--font-bold);}
  .tc_heading--3{font-size:var(--font-28);}
  .tc_heading--4{font-size:var(--font-24);}
  .tc_heading--5{font-size:var(--font-20);}
  .tc_heading--6{font-size:var(--font-18);}
}
